<template>
  <a-affix :offset-top="10" class="cuy-menu">
    <a-menu>
      <a-menu-item
        v-scroll-to="'#' + item.scrollTo"
        v-for="(item, index) in list"
        :key="index">{{item.text}}</a-menu-item
      >
    </a-menu>
  </a-affix>
</template>

<script>
export default {
  name: "CuyMenu",
  props: {
    list: Array
  }

};
</script>

<style lang="less">
.cuy-menu {
  .ant-menu-item-selected {
    background: transparent;
    color: #f5c042;
    background-color: white !important;
  }
  .ant-menu-item {
    font-weight: 600;
    height: auto;
    white-space: initial;
  }
  .ant-menu-item:hover {
    color: #f5c042;
  }
}

@media (max-width: 728px) {
  .cuy-menu {
    display: none;
  }
}
</style>
