<template>
  <div class="politicas">
    <helmet-provider>
      <helmet>
        <title>eXIM | {{legales.title}}</title>
        <meta name="keywords" :content="legales.meta_keywords" />
        <meta name="description" :content="legales.meta_description" />
      </helmet>
    </helmet-provider>
    <CuyMenu :list="legales.menu" />
    <div class="politicas-card">
      <div class="politicas-child" :class="$route.params.slug">
        <div v-if="legales.icon">
          <img :src="require(`../assets/vision/${legales.icon}`)" />
        </div>
        <h2 class="subtitle" v-if="legales.subtitle">{{legales.subtitle}}</h2>
        <div v-html="legales.body"></div>
      </div>
    </div>
  </div>
</template>
<script>
import CuyMenu from "../components/CuyMenu";
import { Helmet, HelmetProvider } from "@jnields/vue-helmet";

export default {
  name: "Page",
  components: {
    Helmet,
    HelmetProvider,
    CuyMenu,
  },
  computed: {
    legales() {
      return this.$store.getters.getLegalesBySlug(this.$route.params.slug);
    },
  },
};
</script>

<style lang="scss">
.politicas {
  display: grid;
  grid-template-columns: 30% 1fr;

  &-child {
    display: grid;
    gap: 1.5rem;
    margin: 2rem;
  }
  &-grid {
    display: flex;
  }

  .politicas-card {
    max-width: 70%;
    display: grid;
    gap: 1rem;

    h3,
    .subtitle {
      font-family: "Bungee", cursive;
      color: #132d2f;
      margin: 0;
      strong {
        font-weight: 100;
      }
    }

    .subtitle {
      color: #444;
    }

    .subtitle {
      text-align: center;
    }

    img {
      display: block;
      margin: 1rem 0;
      max-width: 100%;
    }
    p {
      strong {
        color: #444;
        display: block;
        margin: 1rem 0;
      }
    }
  }
}
.ant-menu {
  height: 100%;
  max-width: 400px;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (max-width: 728px) {
  .politicas {
    grid-template-columns: 1fr;
    &-title {
      max-width: 100%;
    }
    &-list {
      &-item {
        max-width: 100%;
      }
    }
    .cuy-menu {
      display: none;
    }
    .politicas-card {
      max-width: 100%;
    }
  }
}
</style>